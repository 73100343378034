#main button{
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    margin: 10px;
    background: none;
    background: #1ca4ff;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 5em;
    font-size: medium;
}

#main button:hover{
    background-color: #0f5483;
}

.user-button{
    position: relative;
}

.user-button button{
    position: relative;
    border: 0cm;
    top: 10em;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background: none;
    color: #fff;
    width: 80%;
    height: 20em;
    font-size: large;
    background-image: url("../public/button_off.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.user-button button:active{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-image: url("../public/button_on.png");
}

.user-button .he-text{
    position: absolute;
    font-size: 0px;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    animation: anim 1s forwards;
    text-align: center;
    width: 100%;
}

@keyframes anim{
    0%{
        content: "へぇ";
        transform: translateY(-120px);
        opacity: 100%;
        font-size: 30px;
    }

    100%{
        content: "へぇ";
        transform: translateY(-350px);
        opacity: 0%;
        font-size: 150px;
    }
}

#result p{
    text-align: center;
    font-size: 1000%;
    margin: 0;
}

div{
    text-align: center;
}

/* Container */
label.toggle {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    left: 3em;
    margin: 3em;
}

/* Hide default checkbox */
label.toggle input[type='checkbox'] {
    display: none;
}

/* Switch button moving area */
.toggle .moving-area {
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    margin-top: 0px;
    position: absolute;
    border-radius: 50px;
    width: 10em;
    height: 4em;
    background-color: rgb(213, 213, 213);
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: space-evenly;
}

/* Hide one of each buttons exclusively*/
.toggle input[type='checkbox']:not(:checked) + .moving-area .toggle-on {
    visibility: hidden;
}

.toggle input[type='checkbox']:checked + .moving-area .toggle-off {
    visibility: hidden;
}

.moving-area .toggle-on {
    position: relative;
    bottom: -1em;
    font-size: large;
    color: #fff;
}

.moving-area .toggle-off {
    position: relative;
    bottom: -1em;
    font-size: large;
}

/* Switch botton shape */
.toggle .toggle-button {
    position: absolute;
    left: -6em;
    bottom: -2em;
    width: 21px;
    height: 21px;
    padding: 1em;
    border-radius: 100%;
    border-width: 10px;
    background-color: #363636;
    transition: transform 0.3s ease;
}	

/* Transition when switching on */
.toggle input[type='checkbox']:checked ~ .toggle-button {
    transform: translateX(5em);
    background-color: #363636;
}

.toggle input[type='checkbox']:checked + .moving-area {
    background-color: #1ca4ff;
}